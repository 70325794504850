.car-search-form {
    max-width: 960px;

    .no-prefill {
        -webkit-text-security: disc;
    }
}

.car-detail-view .car-detail-info {
    max-width: 100em;
    min-width: 480px;
    margin: auto;

    .result-container .details-container .details-row {
        border-top: none;
    }

    .car-info-container {
        padding: 1em;
        width: 49%;
        display: inline-block;

        @media only screen and (max-width: 1032px) {
            width: 100%;
        }
    }

    .car-info-split-view {
        width: 100%;

        .car-info-items-container .car-info-item .info-data-large {
            font-size: 2.4em;
        }

        .car-info-items-container .car-info-item .info-data-normal {
            @media only screen and (max-width: 1032px) and (min-width: 720px) {
                font-size: 1.4em;
            }
        }

        .car-dynamic-info {
            border-left: none;
        }
    }

    .car-static-info {
        margin-right: 0.5em;
        vertical-align: top;
        float: left;

        .active {
            color: $green;
        }

        .inactive {
            color: $redDark;
        }

        .ui-dropdown {
            min-width: 95px;
        }

        .box-dropdown-container {
            background-color: $lightSkyBlueColor;
            padding-left: 1em;
        }

        .sim-info-container {
            padding: 1.5em;
            background-color: $lightSkyBlueColor;

            .sim-info-table {
                max-height: 200px;
                overflow-y: auto;
            }

            .summary-table thead tr th {
                border-bottom: 2px solid $brandGray02;
            }

            .active-sim {
                background-color: $lightBrilliantOrange;
            }
        }
    }

    .car-position-map {
        width: 100%;
    }

    .car-dynamic-info {
        padding: 0 1.5em;
        margin-bottom: 0;
        min-width: 30em;
        border-left: 1px solid $brandGray04;
        float: right;

        @media only screen and (max-width: 1032px) {
            border-left: none;
        }

        .fa-refresh {
            font-size: 1.5em;
        }

        .car-dynamic-info-text {
            padding: 1em 0 0;

            .details-container {
                background-color: $veryPaleOrange;
            }
        }

        h3 a {
            margin-left: 1em;
            cursor: pointer;
            color: $brandGray01;
        }

        h3 a:hover {
            color: $brandBlue;
        }

        h3 a i {
            transition: transform .8s ease-in-out;
            -webkit-transition: -webkit-transform .8s ease-in-out;
            -ms-transition: -ms-transform .8s ease-in-out;
        }

        h3 a i:hover {
            transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
        }
    }

    .car-info-items-container {
        .car-info-item {
            color: $brandGray04;
            background-color: black;
            border: 4px solid $brandBlue;
            border-radius: 5px;
            box-shadow: 4px 4px 8px black;
            padding: 0.5em;
            margin-bottom: 4%;
            font-size: 1.7em;
            display: inline-block;
            width: 48%;

            @media only screen and (max-width: 1032px) and (min-width: 720px) {
                width: 18%;
                font-size: 1em;
                margin-right: 2%;
            }

            @media only screen and (max-width: 719px) {
                width: 48%;
                font-size: 1em;
            }

            i {
                color: $brandBlue;
                font-size: 1.2em;
                margin: 0 0.5em 0.2em 0;
                text-shadow: 0 0 5px blue;
            }

            .info-text {
                text-align: right;
                height: 50px;
                position: relative;

                @media only screen and (max-width: 1032px) {
                    height: 40px;
                }

                span {
                    position: absolute;
                    bottom: 2px;
                    right: 0;
                }
            }

            .info-data-large {
                font-size: 1.4em;
            }

            .info-data-normal {
                font-size: 1.2em;

                @media only screen and (min-width: 1560px) {
                    font-size: 1.6em;
                }

                @media only screen and (max-width: 1032px) and (min-width: 720px) {
                    font-size: 1.4em;
                }
            }

            .label {
                border-top: 2px solid $brandBlue;
                text-align: right;
                font-size: 0.8em;
                padding-top: 0.2em;
                color: $brandBlue;
            }
        }

        .car-info-item:nth-child(odd) {
            margin-right: 4%;

            @media only screen and (max-width: 1032px) {
                margin-right: 2%;
            }
        }
    }

    .plate-edit-btn {
        cursor: pointer;
        font-size: 1.2em;
        margin-left: 0.2em;
        padding: 0.2em;
    }

    .plate-edit-btn:hover {
        color: $brandBlue;
    }
}

.car-detail-troubleshoot-panel-box {
    position: relative;
    top: 20px;
    right: auto;
    box-shadow: none;
    text-align: center;

    .car-detail-troubleshoot-container {
        margin: auto;

        .button {
            min-width: 285px
        }

        .expert-cmd-container {
            display: inline-block;
        }

        .action-container {
            display: inline-block;

            .info-panel {
                width: 400px;
                display: block;
                margin-bottom: 2em;
            }

            .cmd-container {
                display: block;
                width: 400px;

                .ui-lg-4 {
                    width: 100%
                }
            }
        }

        .troubleshoot-btns {
            display: inline-block;
            width: auto;
            max-width: 1100px;
            vertical-align: top;
        }
    }
}


.split-screen-open .car-detail-view .car-detail-info {
    .car-info-container {
        width: 100%;

        .car-info-item {
            width: 48%;
            font-size: 1em;
        }

        .car-info-items-container .car-info-item .info-data-large {
            font-size: 2.4em;
        }

        .car-info-items-container .car-info-item .info-data-normal {
            @media only screen and (max-width: 1032px) and (min-width: 720px) {
                font-size: 1.4em;
            }
        }
    }

    .car-dynamic-info {
        border-left: none;
    }
}

.car-plate-change-modal-content {
    .input-field {
        width: 200px;
    }

    .car-plate-change-info {
        height: 100px;
        color: #d0021b;
        padding-top: 20px;
    }
}

.car-data-search-form {
    display: flex;
    max-width: 500px;
}

.car-data-search-buttons {
    padding-bottom: 50px;
    padding-left: 200px;
}

.car-data-table {
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow-x: visible;
}

.car-data-door-closed-icon {
    background-image: url('~assets/icons/doorOn.svg');
    background-size: contain;
}

.car-data-door-open-icon {
    background-image: url('~assets/icons/doorOff.svg');
    background-size: contain;
}

.car-data-search-form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.car-data-search-form-date {
    display: flex;
}

.car-data-search-form-calendar {
    width: 80% !important;
}

.car-data-search-form-date-label {
    position: relative;
    top: 5px;
    margin-right: 5px;
}

.car-data-fuel-icon {
    background-image: url('~assets/icons/fuel.svg');
    background-size: contain;
    margin-right: 4px;
    margin-bottom: 4px;
}

.car-data-command-details {
    word-wrap: break-word;
}

.car-data-command-user {
    top: 4px;
    margin-right: 4px;
}

.car-data-command-error {
    color: $errorColor
}

.car-data-command-success {
    color: $green
}

.car-data-type {
    color: $charcoal;
}

.car-data-command-row {
    background-color: $lightSkyBlueColor;
}

.car-data-status-row {
    background-color: $veryPaleOrange;
}

.car-data-rfid-row {
    background-color: $lightBrown;
}

.car-data-rfid-card{
    margin-right: 8px;
}

table.car-data-table {
    width: 100%;
    border-spacing: 0;

    thead {
        tr {
            background-color: $brandWhite;
        }

        th {
            font-size: 0.9rem;
            border-bottom: 1px solid $brandGray02;
        }
    }

    tbody {
        tr>td {
            font-size: 0.9rem;
            border-bottom: 1px dashed $lightGray;
            line-height: 1.3;
        }

        tr:last-child>td {
            border-bottom: none;
        }

        a {
            cursor: pointer;
        }
    }

    th,
    td {
        padding: 0.5rem;
        min-height: 3rem;
        max-width: 10rem;
        font-size: 1rem;

        &>label,
        &>span,
        &>i {
            display: inline-block;
            max-width: 100%;
            // line-height: 110%;
            vertical-align: middle;
        }
    }

    th:not([class*="text-"]) {
        text-align: left;
    }

    td a {
        color: $brandBlack;
        text-decoration: underline;
    }
}