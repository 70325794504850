.lat-long-container {

    .lat-long-link {
        font-weight: 700;
        font-size: 0.85rem;
        text-transform: uppercase;
        text-decoration: none;
        color: $darkMenuRouterLinkActiveColor;
    }

    &:hover span {
        cursor: pointer;
    }

}
